<template>
  <div class="cart-page">
    <div class="cart-card">
      <div class="cart-message">
        <div class="cart-message-state">
          <p>
            Lost your password? Please enter your username or email address. You
            will receive a link to create a new password via email.
          </p>
        </div>
      </div>
      <div class="email-box">
       <el-form :label-position="labelPosition" label-width="80px" :model="ruleForm"  :rules="rules" ref="ruleForm" >
        <el-form-item label="Username or email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
         <el-form-item>
          	<div v-if="isCheckIng" class="check-img rotate360">
              <img class="w-h-full" src="@/assets/images/check/check-loading.png" alt="" />
            </div>
            <div v-else-if="isCheckOver" class="check-img">
              <img class="w-h-full" src="@/assets/images/check/check-over.png" alt="" />
            </div>
          <el-button class="car-buy-btn" @click="submitForm('ruleForm')">Reset password</el-button>
        </el-form-item>
      </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { sendEmailChangeCodeApi } from "@/network/api.js";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "cart",
  data() {
    return {
       labelPosition: 'top',
       isCheckIng: false,
			 isCheckOver: false,
        ruleForm: {
          email: '',
        },
        rules: {
          email: [
            { required: true, message: 'Please input your email', trigger: 'blur' }
          ],
       
        }

    };
  },
  computed: {
    ...mapGetters(["isVip"]),
  },
  created() {
    const token = localStorage.getItem("token");
    console.log("token", token);
  },
  methods: {
      async submitForm(formName) {
        if(!this.$refs[formName].validate){
          return 
        }
        const params = {
					email: this.ruleForm.email,
				};
				const res = await sendEmailChangeCodeApi(params);
        if(res.code == '1'){
          this.$message.success('The verification email was successfully sent. Please go to your email to check');
        }
      },
} 
};
</script>

<style lang="scss" scoped>
.cart-page {
  width: 100%;
  height: 9.8rem;
  overflow: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding: 20px 0;
  background: #fff;

  .cart-card {
    position: relative;
    width: 60%;
    margin: 0 auto;
    background-color: #fff;

    .cart-message {
      width: 100%;
      color: #333;

      .cart-message-state {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        padding: 10px 0;
      }
    }

    .email-box {
      .car-buy-btn {
        background-color: #ffb100;
        color: #fff;
        font-weight: bold;
        padding: 14px 24px;
      }
      .car-buy-btn:hover {
        background-color: #0e1422;
      }
    }
  }
}
</style>
<style lang="scss">
.el-form-item {
  text-align: left;
}
// .el-button--primary {
//   background-color: #ffb100 !important;
//   border-color: #ffb100 !important;
//   font-weight: 600;
// }
// .el-input__inner {
//   width: 50% !important;
// }
.el-input__inner:hover {
  // color: #ffb100;
  border-color: #ffb100;
}
// .el-form-item {
//   margin-bottom: 0.1rem !important;
// }

.el-form--label-top .el-form-item__label {
  display: block;
}
</style>
